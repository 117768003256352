import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AdminNav from '../AdminNav';

import CategoryForm from './CategoryForm';

interface IProps {
}

const AddCategory: React.FC<IProps> = (props) => {
  useEffect(() => {
    document.title = "Add Category | Gladvent Calendar";

    // axios.get('/api/admincategories')
    // .then((response: AxiosResponse) => {
    //   setCategory(response.data);
    // });
  }, []);

  return (
    <>
    <h1>Add Category</h1>

    <CategoryForm />

    <AdminNav />
</>
  )
}

export default AddCategory;