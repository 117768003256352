import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

interface IProps {
}

const AdminNav: React.FC<IProps> = (props) => {
  return (
    <>
    <ul className="admin-nav">
      <li><Link to='/'>Main Site</Link></li>
      <li><Link to='/admin'>Dashboard</Link></li>
      <li><Link to='/admin/days'>Days</Link></li>
      <li><Link to='/admin/categories'>Categories</Link></li>
      <li><Link to='/admin/outlets'>Outlets</Link></li>
      <li><Link to='/admin/places'>Places</Link></li>
      <li><Link to='/admin/users'>Users</Link></li>
    </ul>
</>
  )
}

export default AdminNav;