import React, { useState, useEffect } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import AdminNav from '../AdminNav';

// @ts-ignore
import queryString from 'query-string';

import axios from '../../../dealio-axios';
import { AxiosResponse } from 'axios';

interface IProps {
}

const AdminCategories: React.FC<any> = (props: any) => {
  const [categories, setCategories] = useState([]);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    document.title = "Admin Categories | Gladvent Calendar";

    axios.get('/api/admincategories')
    .then((response: AxiosResponse) => {
      setCategories(response.data);
    });

    const qs = queryString.parse(props.location.search);
    if (qs.saved) {
      setSaved(true);
    }
  }, []);

  return (
    <>
    <h1>Categories</h1>

    {saved && <Alert variant="success">Category has been saved</Alert>}

    <Table responsive hover>
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {categories?.map((category: any, index: number) => (
          <tr key={index}>
            <td>{category.name}</td>
            <td><Link to={`/admin/categories/edit/${category.id}`}>edit</Link></td>
          </tr>
        ))}
      </tbody>
    </Table>

    <p>
      <Link className='btn btn-primary' to='/admin/categories/add'>Add</Link>
    </p>

    <AdminNav />
</>
  )
}

export default AdminCategories;