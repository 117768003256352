import React from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router-dom';
import GladventApp from './Gladvent/GladventApp';

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  exact: any;
  path: any;
}

const GladventAdminRoute: React.FC<IProps> = ({component: Component, ...rest}) => {
  const isLoggedIn = window.localStorage.getItem('jwt') ?? false;
  // TODO: I think there's a better way to do this
  const hasAdminRole = window.localStorage.getItem('user:roles')?.includes('Admin');

  return (
    <>
    {!isLoggedIn && <Redirect to={'/login'} />}

    <Route
      {...rest}
      render={(props: any) => hasAdminRole === true ? <GladventApp><Component {...props} /></GladventApp> : <Redirect to={'/account'} />}
    />
    </>
  )
}

export default GladventAdminRoute;