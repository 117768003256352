import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import axios from '../../dealio-axios';
import { AxiosResponse } from 'axios';
import { Link, Redirect } from 'react-router-dom';

import {
  Form as FormikForm,
  Formik,
} from 'formik';
import * as yup from 'yup';

import Aux from '../../hoc/_Aux';

interface EmailTemplateFormProps {
  emailTemplate?: any;
}

const EmailTemplateForm: React.FC<EmailTemplateFormProps> = (props) => {
  const [saved, setSaved] = useState(false);
  let method = props.emailTemplate != null ? 'update' : 'add';

  const emailTemplateSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  useEffect(() => {
  }, []);

  const onSubmit = async (values: any) => {
    // do something with it
    console.log(values);

    axios.post('/api/emailtemplates/' + method, values).then((response: AxiosResponse) => {
      console.log(response.data);

      // if it worked then what?
      setSaved(true);
    });
  } 

  const required = (value: any) => (value ? undefined : 'Required');

  return (
    <Aux>
      {saved === true ? <Redirect to="/admin/email-templates" /> : null}

      <Formik
        validationSchema={emailTemplateSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("form values", values);
          onSubmit(values);
          setSubmitting(false);
        }}
        enableReinitialize={true}
        initialValues={props.emailTemplate || {name: ''}}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={values?.id} />

            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.name && errors.name ? "error" : ""}
              />
              {touched.name && errors.name ? (
                <div className="error-message">{errors.name}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="name">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                placeholder="Subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.subject && errors.subject ? "error" : ""}
              />
              {touched.subject && errors.subject ? (
                <div className="error-message">{errors.subject}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="heading">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                name="heading"
                placeholder="Heading"
                value={values.heading}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.heading && errors.heading ? "error" : ""}
              />
              {touched.heading && errors.heading ? (
                <div className="error-message">{errors.heading}</div>
              ) : null}
            </Form.Group>

            {props.emailTemplate && !props.emailTemplate.parentId && <Form.Group controlId="html">
              <Form.Label>HTML</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                style={{fontFamily: 'courier'}}
                type="text"
                name="html"
                placeholder="HTML"
                value={values.html}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.html && errors.html ? "error" : ""}
              />
              {touched.html && errors.html ? (
                <div className="error-message">{errors.html}</div>
              ) : null}
            </Form.Group>
            }

            <Form.Group controlId="text">
              <Form.Label>Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name="text"
                placeholder="Text"
                value={values.text}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.text && errors.text ? "error" : ""}
              />
              {touched.text && errors.text ? (
                <div className="error-message">{errors.text}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="buttonLink">
              <Form.Label>Button Link</Form.Label>
              <Form.Control
                type="text"
                name="buttonLink"
                placeholder="Button Link"
                value={values.buttonLink}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.buttonLink && errors.buttonLink ? "error" : ""}
              />
              {touched.buttonLink && errors.buttonLink ? (
                <div className="error-message">{errors.buttonLink}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="buttonText">
              <Form.Label>Button Text</Form.Label>
              <Form.Control
                type="text"
                name="buttonText"
                placeholder="Text"
                value={values.buttonText}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.buttonLink && errors.buttonText ? "error" : ""}
              />
              {touched.buttonText && errors.buttonText ? (
                <div className="error-message">{errors.buttonText}</div>
              ) : null}
            </Form.Group>

            <button
              type="submit"
              className="btn btn-primary shadow-2 mb-4"
              disabled={isSubmitting}
            >
              Save
            </button>
          </FormikForm>
        )}
      </Formik>

      <p>
        <Link className="btn btn-outline-secondary" to="/admin/email-templates">Back to Email Templates</Link>
      </p>

    </Aux>
  );
}

export default EmailTemplateForm;