import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link, Redirect, useParams } from 'react-router-dom';
import AdminNav from '../AdminNav';

import axios from '../../../dealio-axios';
import { AxiosResponse } from 'axios';
import OutletForm from './OutletForm';

interface IProps {
}

const EditOutlet: React.FC<IProps> = (props) => {
  const [outlet, setOutlet] = useState([]);
  let { outletId } = useParams();

  useEffect(() => {
    document.title = "Edit Outlet | Gladvent Calendar";

    axios.get('/api/adminoutlets/' + outletId)
    .then((response: AxiosResponse) => {
      setOutlet(response.data);
    });
  }, []);

  return (
    <>
    <h1>Edit Outlet</h1>

    <OutletForm outlet={outlet} />

    <AdminNav />
</>
  )
}

export default EditOutlet;