import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link, Redirect, useParams } from 'react-router-dom';
import AdminNav from '../AdminNav';

import axios from '../../../dealio-axios';
import { AxiosResponse } from 'axios';
import DayForm from './DayForm';

interface IProps {
}

const EditDay: React.FC<IProps> = (props) => {
  const [day, setDay] = useState([]);
  let { dayId } = useParams();

  useEffect(() => {
    document.title = "Edit Day | Gladvent Calendar";

    axios.get('/api/admindays/' + dayId)
    .then((response: AxiosResponse) => {
      setDay(response.data);
    });
  }, []);

  return (
    <>
    <h1>Edit Day</h1>

    <DayForm day={day} />

    <AdminNav />
</>
  )
}

export default EditDay;