let backendHost;
const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;

// if (hostname === 'localhost') {
//   backendHost = 'https://api.realsite.com';
// } else if (hostname === 'staging.realsite.com') {
//   backendHost = 'https://staging.api.realsite.com';
// } else if(/^qa/.test(hostname)) {
//   backendHost = `https://api.${hostname}`;
// } else {
//   backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:8080';
// }

if (hostname === 'localhost') {
  backendHost = 'https://localhost:5033';
} else {
  backendHost = `${window.location.protocol}//${window.location.host}`;
}

export const API_ROOT = backendHost;
//export const API_ROOT = `${backendHost}/api/${apiVersion}`;