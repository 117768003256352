import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AdminNav from './AdminNav';

interface IProps {
}

const Users: React.FC<IProps> = (props) => {
  useEffect(() => {
    document.title = "Admin | Gladvent Calendar";
  }, []);

  return (
    <>
    <h1>Admin Users</h1>

    placesUsers

    table

    <AdminNav />
</>
  )
}

export default Users;