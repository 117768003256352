import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import axios from '../../dealio-axios';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import EmailTemplateForm from './EmailTemplateForm';

import Aux from '../../hoc/_Aux';

const EditEmailTemplate: React.FC = (props: any) => {
  const [emailTemplate, setEmailTemplate] = useState<any>([]);
  let { emailTemplateId } = useParams();

  useEffect(() => {
    document.title = 'Edit Email Template | Gladvent Calendar';

    axios.get(`/api/emailtemplates/${emailTemplateId}`)
    .then((response: AxiosResponse) => {
      setEmailTemplate(response.data);
    });
  }, []);

  return (
    <Aux>
      <h1>Edit Email Template</h1>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <EmailTemplateForm emailTemplate={emailTemplate} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}

export default EditEmailTemplate;