import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import AdminNav from '../AdminNav';

import axios from '../../../dealio-axios';
import { AxiosResponse } from 'axios';
import OutletForm from './OutletForm';

interface IProps {
}

const AddOutlet: React.FC<IProps> = (props) => {
  const [outlets, setOutlets] = useState([]);

  useEffect(() => {
    document.title = "Add Outlet | Gladvent Calendar";

    axios.get('/api/adminoutlets')
    .then((response: AxiosResponse) => {
      console.log(response.data);
      setOutlets(response.data);
    });
  }, []);

  return (
    <>
    <h1>Add Outlet</h1>

    <OutletForm />

    <AdminNav />
</>
  )
}

export default AddOutlet;