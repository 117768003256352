import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

interface IProps {
  outlet: any
}

const BusinessRow: React.FC<IProps> = (props) => {
  return (
    <div className='business-row'>
      <div className={`br-image ${props.outlet.isLogo ? 'br-image-logo' : null}`}>
        <img src={props.outlet.image} alt={props.outlet.name} />
      </div>

      <div className='br-main'>
        <span className="br-name">{props.outlet.name}</span>
        <span className="br-place-name">{props.outlet.location}, <span>{props.outlet.postcode}</span></span>

        <p>{props.outlet.description}</p>
      </div>

      <div className='br-link'>
        <a href={props.outlet.url} target="_blank" rel="noreferrer" className="btn btn-secondary">Visit Site</a>
      </div>
    </div>
  )
}

export default BusinessRow;