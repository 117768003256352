import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

interface IProps {
}

const TermsAndConditions: React.FC<IProps> = (props) => {
  useEffect(() => {
    document.title = "Terms &amp; Conditions | Gladvent Calendar";
  }, []);

  return (
    <>
    <h1>Terms &amp; Conditions</h1>
</>
  )
}

export default TermsAndConditions;