import React from 'react';

interface IProps {
}

const GladventPlainApp: React.FC<IProps> = (props) => {
  return (
    <div className="snow-me">
      {[...Array(100 + new Date().getDate() * 4)].map((s: any, index: number) => (
        <div className="snow" key={index}></div>
      ))}

      <div className="plain-container">
        {props.children}
      </div>
    </div>
  )
}

export default GladventPlainApp;