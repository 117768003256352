import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Image, Alert } from 'react-bootstrap';

import axios from '../../dealio-axios';
import { AxiosResponse } from 'axios';

import { Link, Redirect, useHistory } from 'react-router-dom';

// @ts-ignore
import queryString from 'query-string';

import Aux from '../../hoc/_Aux';

import logo from '../../assets/images/glc-logo.png';
import GladventPlainApp from '../GladventPlainApp';

const ConfirmEmailAddress: React.FC = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [badRequest, setBadRequest] = useState(false);
  let history = useHistory();

  useEffect(() => {
      document.title = 'Confirm Email Address | Gladvent Calendar';

      if (window.localStorage.getItem('jwt')) {
          setIsLoggedIn(true);
      }

      // Get the QS
      const qs = queryString.parse(props.location.search);

      // If we're missing something, just redirect back to the main page
      if (!qs.email || !qs.token) {
        history.push('/');
      }

      // Check the email address
      let data = {
        emailAddress: qs.email,
        token: qs.token
      }

      console.log('data', data);

      axios.post('/api/user/confirm-email', data)
      .then((response: AxiosResponse) => {
        console.log("response", response);     
        setLoaded(true);

        if (response.status === 200) {
        } else if (response.status === 400) {
          // Bad request
          setBadRequest(true);
        }
      });

    }, []);

  const sendNewToken = () => {
    
  }

  return (
    <GladventPlainApp>
      {isLoggedIn && <Redirect to="/dashboard" />}
      <div className="main auth-wrapper" style={{flexDirection: 'column'}}>
        
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                  <img src={logo} className="logo" alt="Gladvent Calendar"/>
              </div>

              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <h2 className='mb-4'>Confirm Your Email Address</h2>

                    {!loaded && <p>Loading...</p>}
                    {loaded && !badRequest && <Alert variant="success">Your email address has been confirmed</Alert>}
                    {loaded && badRequest && <Alert variant="danger">Your token has expired, <a onClick={sendNewToken}>click to be sent a new token</a></Alert>}

                    <p>
                      <Link className="btn btn-primary" to="/login">Login</Link>
                    </p>
                  </Col>
                </Row>
              </Container>
      </div>
      </div>
      </div>
      </div>
    </GladventPlainApp>
  );
}

export default ConfirmEmailAddress;