import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button, Image, Alert } from 'react-bootstrap';

import axios from '../../dealio-axios';
import { AxiosResponse } from 'axios';
import { Link, Redirect, useHistory } from 'react-router-dom';
// @ts-ignore
import queryString from 'query-string';

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Form as FormikForm, Formik } from 'formik';
import * as yup from 'yup';

import GladventPlainApp from '../GladventPlainApp';

const SetPassword: React.FC = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [badRequest, setBadRequest] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [passwordTextMode, setPasswordTextMode] = useState(false);
  let history = useHistory();

  const passwordSchema = yup.object().shape({
    password: yup.string()
    .required("Password is Required.")
    //.max(13,"Too long")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one Uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one Lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[^0-9a-zA-Z]/, "Password must contain at least one special character letter"),
    passwordConfirm: yup.string()
     .oneOf([yup.ref('password'), ''], 'Confirmation must match password field')
     .required('Password confirm is required')
  });

  useEffect(() => {
      document.title = 'Set Password | Gladvent Calendar';

      if (window.localStorage.getItem('jwt')) {
          setIsLoggedIn(true);
      }

      // Get the QS
      const qs = queryString.parse(props.location.search);

      // If we're missing something, just redirect back to the main page
      if (!qs.email || !qs.token) {
        history.push('/');
      }
    }, []);

  const setPassword = (values: any) => {
    const qs = queryString.parse(props.location.search);

    // Check the email address
    let data = {
      emailAddress: qs.email,
      token: qs.token,
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    }

    console.log('data', data);

    axios.post('/api/user/set-password', data)
    .then((response: AxiosResponse) => {
      console.log("response", response);     
      setIsSaved(true);

      if (response.status === 200) {
      } else if (response.status === 400) {
        // Bad request
        setBadRequest(true);
      }
    });
  }

  return (
    <GladventPlainApp>
      {isLoggedIn && <Redirect to="/dashboard" />}
      <div className="main auth-wrapper" style={{flexDirection: 'column'}}>
    
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                  <img src="/images/cropped-Merakoi-logo-corail_2x.png" alt="Merakoi logo" />
              </div>

              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <h4 className='mb-4'>Create a Password for Your Gladvent Calendar Admin Account</h4>

                    <Formik
                  validationSchema={passwordSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log("form values", values);
                    setPassword(values);
                    setSubmitting(false);
                  }}
                  enableReinitialize={true}
                  initialValues={{password: '', passwordConfirm: ''}}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      isSubmitting,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                  <FormikForm onSubmit={handleSubmit}>
                    <Form.Group controlId="password" className="relative-group">
                      <Form.Label>Create Password</Form.Label>
                      <Form.Control
                        type={passwordTextMode ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.password && errors.password ? "error" : ""}
                      />
                      <FontAwesomeIcon icon={faEye} onClick={() => setPasswordTextMode(!passwordTextMode)} />
                      {touched.password && errors.password ? (
                        <div className="error-message">{errors.password}</div>
                      ): null}
                    </Form.Group>

                    <Form.Group controlId="passwordConfirm" className="relative-group">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type={passwordTextMode ? "text" : "password"}
                        name="passwordConfirm"
                        placeholder="Confirm Password"
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.passwordConfirm && errors.passwordConfirm ? "error" : ""}
                      />
                      <FontAwesomeIcon icon={faEye} onClick={() => setPasswordTextMode(!passwordTextMode)} />
                      {touched.passwordConfirm && errors.passwordConfirm ? (
                        <div className="error-message">{errors.passwordConfirm}</div>
                      ): null}
                    </Form.Group>
                    

                    <Button type="submit" className="btn btn-primary">Set Password</Button>

                    {isSaved && <Alert variant="success">Your password has been saved. <Link className="btn btn-primary" to="login">Login to Your Account</Link></Alert>}
                    {isError && <Alert variant="danger">Your password could not be set, contact an administrator.</Alert>}

                    </FormikForm>
                    )}
                    </Formik>
                  </Col>
                </Row>
              </Container>
      </div>
      </div>
      </div>
      </div>
    </GladventPlainApp>
  );
}

export default SetPassword;