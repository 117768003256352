import React from 'react';

import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';

interface SaveButtonProps {
  processing: boolean,
  onClick?: any
}

const SaveButton: React.FC<SaveButtonProps> = (props) => {
  return <Button type="submit" className="btn btn-primary" disabled={props.processing} onClick={props.onClick}>
      {!props.processing && <>{props.children}</>}
      {props.processing && <Loader type="ThreeDots" color="#ffffff" height={15} />}
    </Button>
}

export default SaveButton;
