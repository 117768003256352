import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Tabs, Tab, Button } from 'react-bootstrap';

import axios from '../../dealio-axios';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

import Aux from '../../hoc/_Aux';

const EmailTemplates: React.FC = (props: any) => {
  const [emailTemplates, setEmailTemplates] = useState<any>([]);

  useEffect(() => {
    document.title = 'Email Templates | Gladvent Calendar';

    axios.get('/api/emailtemplates')
    .then((response: AxiosResponse) => {
      setEmailTemplates(response.data);
    });
  }, []);

  return (
    <Aux>
      <h1>Email Templates</h1>

      <Row>
        <Col>
        <Card>
            <Card.Body>
          <Table responsive hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
                <th>Is Master?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {emailTemplates.map((template: any, index: number) => {
                return <tr key={index}>
                  <td>{template.name}</td>
                  <td>{template.action}</td>
                  <td>{template.parentId ? '' : 'Yes'}</td>
                  <td><Link className="btn btn-outline-primary" to={`/admin/email-templates/edit/${template.id}`}>Edit</Link></td>
                </tr>
              }
              )}
            </tbody>
          </Table>

          <p>
            <Link className="btn btn-secondary" to="/admin/email-templates/add">Add Email Template</Link>
          </p>

          </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}

export default EmailTemplates;