import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../../App/layout/AdminLayout/Breadcrumb";

import { FormLabel, FormGroup, FormControl, Row, Col, Card, Table, Tabs, Tab, Button, Form, Image, Modal } from 'react-bootstrap';

import axios from  '../../../dealio-axios';
import Axios, { AxiosResponse } from 'axios';

import { useFormik, Formik, Form as FormikForm, Field, FormikConfig } from 'formik';
import * as yup from 'yup';
import GladventPlainApp from '../../GladventPlainApp';

import logo from '../../../assets/images/glc-logo.png';

const Register : React.FC = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [viewedTerms, setViewedTerms] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const registerSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    emailAddress: yup.string().email("Please enter a valid email address").required("Email address is required"),

    password: yup.string()
      .required("Password is Required.")
      //.max(13,"Too long")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one Uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one Lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/[^0-9a-zA-Z]/, "Password must contain at least one special character"),
    //acceptTerms: yup.bool().oneOf([true], 'You must accept the terms to complete registration'),
  });

  useEffect(() => {
    document.title = 'Register | Gladvent Calendar';

    // If you have a JWT then just redirect now
    if (window.localStorage.getItem('jwt'))
        setIsLoggedIn(true);
  }, []);

  const updateUser = async (values: any) => {
    console.log("register");

    axios.post('/api/user/register', values)
    .then((response: AxiosResponse) => {
      console.log("response", response);     

      if (response.status === 200) {
        console.log("setting localstorage");
        //window.localStorage.setItem('jwt', response.data.token);

        //setIsLoggedIn(true);
        setRegistrationComplete(true);
      }
    })
    .catch((response: AxiosResponse) => {
      //console.log("not sure");
      alert("Cannot register your account");
    });
  }

  const handleClose = () => {
    setShowTermsAndConditions(false);
  }

  const acceptTerms = () => {
    // something here
    console.log("terms accepted");
  //  setAcceptTerms(true);

    setShowTermsAndConditions(false);
  }

  const showTermsModal = () => {
    setShowTermsAndConditions(true);
    setViewedTerms(true);
  }

  return(
    <GladventPlainApp>
    <Aux>
      {isLoggedIn && <Redirect to="/dashboard" />}
      <Breadcrumb/>

      <Formik
        validationSchema={registerSchema}
        onSubmit={(values, { setSubmitting }) => {
          //updateUser();
          console.log("form values", values);
          updateUser(values);
          setSubmitting(false);
        }}
        enableReinitialize={true}
        initialValues={{firstName: '', lastName: '', emailAddress: '', password: '', acceptTerms: false}}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            values,
            touched,
            isValid,
            errors,
          }) => (
        <FormikForm onSubmit={handleSubmit}>
          <div className="main auth-wrapper">
            <div className="auth-content" style={{width: 1000}}>
              <div className="auth-bg">
                <span className="r"/>
                <span className="r s"/>
                <span className="r s"/>
                <span className="r"/>
              </div>
              <div className="card">
                <div className="card-body">
                  {registrationComplete && <div>
                    <h3>Thank you.</h3>
                      <p>Your Gladvent Calendar account has been created. You will receive an email to the email address provided,
                      to confirm your account and continue the registration process.</p>
                      
                      <p>
                          <Link className="btn btn-outline-primary" to='/'>Visit Homepage</Link>
                      </p>
                    </div>}
                  {!registrationComplete && <><div className="mb-4">
                      <i className="feather icon-user-plus auth-icon"/>
                  </div>

                  <Link to='/'><img src={logo} className="logo" alt="Gladvent Calendar"/></Link>
                  <h3 className="mb-4">Register</h3>

                  <Form.Group controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control name="firstName" type="text" placeholder="First name" value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.firstName && errors.firstName ? "error" : ""}
                    />
                    {touched.firstName && errors.firstName ? (
                      <div className="error-message">{errors.firstName}</div>
                    ): null}
                  </Form.Group>

                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control name="lastName" type="text" placeholder="Last name" value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.lastName && errors.lastName ? "error" : ""}
                    />
                    {touched.lastName && errors.lastName ? (
                      <div className="error-message">{errors.lastName}</div>
                    ): null}
                  </Form.Group>
                  
                  <Form.Group controlId="emailAddress">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control name="emailAddress" type="email" placeholder="Email Address" value={values.emailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.emailAddress && errors.emailAddress ? "error" : ""}
                    />
                    {touched.emailAddress && errors.emailAddress ? (
                      <div className="error-message">{errors.emailAddress}</div>
                    ): null}
                  </Form.Group>   

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control name="password" type="password" value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.password && errors.password ? "error" : ""}
                    />
                    {touched.password && errors.password ? (
                      <div className="error-message">{errors.password}</div>
                    ): null}
                  </Form.Group>

                  {/* <Form.Group controlId="acceptTerms">
                    <Form.Label>Accept <span onClick={showTermsModal}>terms &amp; conditions</span></Form.Label>
                    <Form.Check name="acceptTerms" checked={values.acceptTerms} onChange={handleChange} disabled={!viewedTerms}>
                    </Form.Check>
                  </Form.Group> */}

                  {/*<div className="form-group text-left">
                        <div className="checkbox checkbox-fill d-inline">
                          <input type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2"/>
                          <label htmlFor="checkbox-fill-2" className="cr">Send me the <a href={DEMO.BLANK_LINK}> Newsletter</a> weekly.</label>
                        </div>
                      </div>*/}
                  <button type="submit" className="btn btn-primary shadow-2 mb-4" disabled={isSubmitting}>Register</button>
                  
                  <p className="modal-alternative mb-0 text-muted">Already have an account? <Link to="/login">Login</Link></p>
            </>}
          </div>
          </div>
        </div>
      </div>
    </FormikForm>
          )}
          </Formik>

          <Modal show={showTermsAndConditions} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Accept Terms &amp; Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>Terms and conditions text...</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={acceptTerms}>
            Accept Terms &amp; Conditions
          </Button>
        </Modal.Footer>
      </Modal>
    </Aux>
    </GladventPlainApp>
  );
}

export default Register;