import React, { useState } from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect, Link } from 'react-router-dom';
import GladventApp from './Gladvent/GladventApp';

import { faBell, faHome, faCog, faEnvelope, faSignOutAlt, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  exact: any;
  path: any;
}

const UserDetails: React.FC = (props: any) => {
  const [name, setName] = useState(window.localStorage.getItem('user:displayname'));

  // TODO: I think there's a better way to do this
  const hasAdminRole = window.localStorage.getItem('user:roles')?.includes('Admin');

  const logout = () => {
    window.localStorage.removeItem('jwt');

    window.localStorage.removeItem('user:displayname');
    window.localStorage.removeItem('user:username');
    window.localStorage.removeItem('user:image');
    window.localStorage.removeItem('user:roles');

    // Redirect the user back to the main page
    window.location.href = "/";
  }


  return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 5}}>
            <div style={{marginRight: 20}}>Logged in as: <Link to='/account'>{name}</Link></div>
            {/* <FontAwesomeIcon icon={faArrowUp} /> */}
            {hasAdminRole && <div style={{marginRight: 20}}><Link to='/admin'>Admin</Link></div>}
            <button onClick={logout}>Logout</button>
  </div>
}

const GladventRoute: React.FC<IProps> = ({component: Component, ...rest}) => {
  const isLoggedIn = window.localStorage.getItem('jwt') ?? false;

  return (
    <GladventApp>
      <UserDetails />

      <Route
        {...rest}
        render={(props: any) => isLoggedIn ? <Component {...props} /> : <Redirect to={'/login'} />}
      />
    </GladventApp>
  )
}

export default GladventRoute;