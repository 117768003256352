import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AdminNav from './AdminNav';

interface IProps {
}

const AdminDashboard: React.FC<IProps> = (props) => {
  const [stats, setStats] = useState();

  useEffect(() => {
    document.title = "Admin | Gladvent Calendar";

    // post to get the stats
  }, []);

  return (
    <>
    <h1>Admin Dashboard</h1>

    <div>
      How many users
      {/* ::{stats.userCount} */}
    </div>

    <div>
      # days
    </div>

    <div>
      # places
    </div>

    <AdminNav />
</>
  )
}

export default AdminDashboard;