import React, { useState, useEffect } from 'react';
import { Form, Table } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';

import axios from '../../../dealio-axios';
import { AxiosResponse } from 'axios';

import {
  Form as FormikForm,
  Formik,
} from "formik";
import * as yup from "yup";

interface IProps {
  day?: any
}

const DayForm: React.FC<IProps> = (props) => {
  const [saved, setSaved] = useState(false);
  let history = useHistory();
  let method = props.day != null ? 'update' : 'add';

  const outletSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const onSubmit = async (values: any) => {
    axios.post('/api/admindays/' + method, values).then((response: AxiosResponse) => {
      // if it worked then what?
      setSaved(true);
      history.push('/admin/days?saved=true');
    });
  }

  return (
    <>
<Formik
        validationSchema={outletSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("form values", values);
          onSubmit(values);
          setSubmitting(false);
        }}
        enableReinitialize={true}
        initialValues={props.day || {name: '', dayNumber: 0, quote: '', subheading: '', description: '', description2: '', categoryId: 0}}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={values?.id} />

            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.name && errors.name ? "error" : ""}
              />
              {touched.name && errors.name ? (
                <div className="error-message">{errors.name}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="dayNumber">
              <Form.Label>Day Number</Form.Label>
              <Form.Control
                type="text"
                name="dayNumber"
                placeholder="Day Number"
                value={values.dayNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.dayNumber && errors.dayNumber ? "error" : ""}
              />
              {touched.dayNumber && errors.dayNumber ? (
                <div className="error-message">{errors.dayNumber}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="quote">
              <Form.Label>Quote</Form.Label>
              <Form.Control
                type="text"
                name="quote"
                placeholder="Quote"
                value={values.quote}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.quote && errors.quote ? "error" : ""}
              />
              {touched.quote && errors.quote ? (
                <div className="error-message">{errors.quote}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="subheading">
              <Form.Label>Subheading</Form.Label>
              <Form.Control
                type="text"
                name="subheading"
                placeholder="Subheading"
                value={values.subheading}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.subheading && errors.subheading ? "error" : ""}
              />
              {touched.subheading && errors.subheading ? (
                <div className="error-message">{errors.subheading}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                placeholder="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.description && errors.description ? "error" : ""}
              />
              {touched.description && errors.description ? (
                <div className="error-message">{errors.description}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="description2">
              <Form.Label>Description 2</Form.Label>
              <Form.Control
                as="textarea"
                name="description2"
                placeholder="Description 2"
                value={values.description2}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.description2 && errors.description2 ? "error" : ""}
              />
              {touched.description2 && errors.description2 ? (
                <div className="error-message">{errors.description2}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="categoryId">
              <Form.Label>Category Id</Form.Label>
              <Form.Control
                type="text"
                name="categoryId"
                placeholder="Category Id"
                value={values.categoryId}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.categoryId && errors.categoryId ? "error" : ""}
              />
              {touched.categoryId && errors.categoryId ? (
                <div className="error-message">{errors.categoryId}</div>
              ) : null}
            </Form.Group>

            <button
              type="submit"
              className="btn btn-primary shadow-2 mb-4"
              disabled={isSubmitting}
            >
              Save
            </button>
          </FormikForm>
        )}
      </Formik>

      <p>
        <Link className="btn btn-outline-secondary" to="/admin/days">Back to Days</Link>
      </p>

</>
  )
}

export default DayForm;