import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams, useHistory } from 'react-router-dom';

import logo from '../assets/images/glc-logo.png';
import calendar from '../assets/images/calendar.gif';
import BusinessRow from './BusinessRow';

import axios from '../dealio-axios';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';

interface IProps {
}

const Calendar: React.FC<IProps> = (props) => {
  const [outlets, setOutlets] = useState([]);
  const [currentDay, setCurrentDay] = useState(0);
  const [action, setAction] = useState({date: '', quote: '', subheading: '', description: '', description2: '', categoryId: 0});
  const [dayText, setDayText] = useState('');
  let { day } = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = "Calendar | Gladvent Calendar";

    let dt = new Date();
    let currentDayNumber = dt.getDate();
    let christmasDone = false;

    if (dt.getFullYear() > 2021 || dt.getDate() > 25) {
      christmasDone = true;
      currentDayNumber = 25;
    }

    // If we have no day, then redirect to the current day
    if (!day || christmasDone || day > currentDayNumber) {
      history.push(`/calendar/${currentDayNumber}`);
    }

    setCurrentDay(currentDayNumber);

    if (day) {
      axios.get(`/api/days/day/${day}`)
      .then((response: AxiosResponse) => {
        console.log(response.data);
        setAction(response.data);

        // Work out the date, 11th month by 0 index = December
        let tmpDate = new Date(2020, 11, day);
        setDayText(format(tmpDate, 'EEEE do MMMM'));

        if (response.data) {
          axios.get(`/api/adminoutlets/category/${response.data.categoryId}`)
          .then((response: AxiosResponse) => {
            console.log(response.data);
            setOutlets(response.data);
          });
        }
      });
    }

  }, [day]);

  return (
    <>
     <div className="gl-title">
        <img src={logo} alt="Gladvent Calendar"/>
      </div>

      <div className="gl-calendar">
        <img src={calendar} alt="Gladvent Calendar"/>
        
        <div className="gl-body-block">
          <div className="calendarBoxes">
          {[...Array(25)].map((x: string, index: number) => {
              return <>
                {index + 1 <= currentDay && index + 1 === currentDay && <div className="calendarBox calendarBox-today" key={index}><Link to={`/calendar/${index + 1}`}>{index + 1}</Link></div>}
                {index + 1 <= currentDay && index + 1 !== currentDay && <div className="calendarBox calendarBox-open" key={index}><Link to={`/calendar/${index + 1}`}>{index + 1}</Link></div>}
                {index + 1 > currentDay && <div className="calendarBox" key={index}>{index + 1}</div>}
              </>
            })}
          </div>

          <div className="gl-body">
            <div className="gl-form">
              <div className="home-hero">
              {/* <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="50,0 100,0 50,100 0,100"></polygon>
          </svg> */}
                {action && <div className="row">
                   <div className="">
                    <h1>{dayText}</h1>
                    
                    <h2 className="first" style={{fontStyle: 'italic'}}>{action.quote}</h2>
                    <h2 className="second">{action.subheading}</h2>

                    <p className="day-text">{action.description}</p>
                    <p className="day-text">{action.description2}</p>

                    <div className="business-rows">
                      {outlets?.map((outlet: any, index: number) => (
                        <BusinessRow outlet={outlet} key={index} />
                      ))}
                    </div>

                    <p>
                      <br/><br/>
                      If you'd like to suggest a business or service in this area (or others), <a style={{color: '#333'}} href="https://forms.gle/YndAJPXX4dz1NuoM8">let me know!</a>
                    </p>

                  </div>
                </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="gl-copy">
        <p>Copyright &copy; 2020 - <a style={{color: '#666'}} href="https://propellersoftware.net">propeller software</a></p>
      </div>
    </>
  )
}

export default Calendar;