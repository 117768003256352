import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams, useHistory } from 'react-router-dom';

import logo from '../assets/images/glc-logo.png';
import calendar from '../assets/images/calendar.gif';
import BusinessRow from './BusinessRow';

import axios from '../dealio-axios';
import { AxiosResponse } from 'axios';

interface IProps {
}

const Listings: React.FC<IProps> = (props) => {
  const [data, setData] = useState([]);
  let { category } = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = "Listings | Gladvent Calendar";

    axios.get(`/api/adminoutlets/listings`)
    .then((response: AxiosResponse) => {
      setData(response.data);
    });
  }, []);

  return (
    <>
     <div className="gl-title">
        <img src={logo} alt="Gladvent Calendar"/>
      </div>

      <div className="gl-calendar">
        <img src={calendar} alt="Gladvent Calendar"/>
        
        <div className="gl-body-block">
          <div className="gl-body">
            <div className="gl-form">
              <div className="home-hero">
                <h1>Listings</h1>


                {data && data.map((category: any, index: number) => (
                  <div className="row">
                    <div className="listings-row">
                      <h2>{category.category.name}</h2>
                      
                      {/* <p className="description">{category.description}</p> */}
                      
                      <div className="business-rows">
                        {category.outlets?.map((outlet: any, index: number) => (
                          <BusinessRow outlet={outlet} />
                        ))}
                      </div>
                    </div>
                  </div>
                ))
                }

                <p>
                  <br/><br/>
                  If you'd like to suggest a business or service in this area (or others), <a style={{color: '#333'}} href="https://forms.gle/YndAJPXX4dz1NuoM8">let me know!</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="gl-copy">
        <p>Copyright &copy; 2020 - <a style={{color: '#666'}} href="https://propellersoftware.net">propeller software</a></p>
      </div>
    </>
  )
}

export default Listings;