import React, { useState, useEffect } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import AdminNav from '../AdminNav';

// @ts-ignore
import queryString from 'query-string';

import { AxiosResponse } from 'axios';
import axios from '../../../dealio-axios';

interface IProps {
}

const Days: React.FC<any> = (props: any) => {
  const [days, setDays] = useState([]);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    document.title = "Days | Gladvent Calendar";

    axios.get('/api/admindays')
    .then((response: AxiosResponse) => {
      setDays(response.data);
    });

    const qs = queryString.parse(props.location.search);
    if (qs.saved) {
      setSaved(true);
    }
  }, []);

  return (
    <>
    <h1>Days</h1>

    {saved && <Alert variant="success">Day has been saved</Alert>}

    <Table responsive hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Day Number</th>
          <th>Quote</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {days.map((d: any, index: number) => (
        <tr key={index}>
          <td>{d.name}</td>
          <td>{d.dayNumber}</td>
          <td>{d.quote}</td>
          <td><Link to={`/admin/days/edit/${d.id}`}>edit</Link></td>
        </tr>
        ))}
      </tbody>
      </Table>

      <Link className="btn btn-primary" to={'/admin/days/add'}>Add Day</Link>

    <AdminNav />
</>
  )
}

export default Days;