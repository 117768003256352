import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './Gladvent/Home';
import Listings from './Gladvent/Listings';
import Calendar from './Gladvent/Calendar';

import NoMatch from './NoMatch';

import Login from './Gladvent/Authentication/Login/Login';
import Register from './Gladvent/Authentication/Register/Register';
import ForgottenPassword from './Gladvent/Authentication/ForgottenPassword';
import ConfirmEmailAddress from './Gladvent/Authentication/ConfirmEmailAddress';
import SetPassword from './Gladvent/Authentication/SetPassword';
import ResetPassword from './Gladvent/Authentication/ResetPassword';
import PrivacyPolicy from './Gladvent/PrivacyPolicy';
import TermsAndConditions from './Gladvent/TermsAndConditions';

import AdminRoute from './AdminRoute';
import PrivateRoute from './PrivateRoute';
import GladventRoute from './GladventRoute';
import GladventPrivateRoute from './GladventPrivateRoute';

import Loader from './App/layout/Loader';

import Account from './Gladvent/Account';

import AdminDashboard from './Gladvent/Admin/AdminDashboard';
import Places from './Gladvent/Admin/Places';
import Users from './Gladvent/Admin/Users';
import Days from './Gladvent/Admin/Days/Days';
import AddDay from './Gladvent/Admin/Days/AddDay';
import EditDay from './Gladvent/Admin/Days/EditDay';
import Categories from './Gladvent/Admin/Categories/Categories';
import AddCategory from './Gladvent/Admin/Categories/AddCategory';
import EditCategory from './Gladvent/Admin/Categories/EditCategory';
import GladventAdminRoute from './GladventAdminRoute';
import AdminOutlets from './Gladvent/Admin/Outlets/AdminOutlets';
import AddEmailTemplate from './Admin/EmailTemplates/AddEmailTemplate';
import EditEmailTemplate from './Admin/EmailTemplates/EditEmailTemplate';
import EmailTemplates from './Admin/EmailTemplates/EmailTemplates';
import AddOutlet from './Gladvent/Admin/Outlets/AddOutlet';
import EditOutlet from './Gladvent/Admin/Outlets/EditOutlet';

// Main Spark
// import "./assets/js/app";


const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader/>}>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/forgotten-password' component={ForgottenPassword} />

          <Route exact path='/confirm-email' component={ConfirmEmailAddress} />
          <Route exact path='/set-password' component={SetPassword} />
          <Route exact path='/reset-password' component={ResetPassword} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/terms-and-conditions' component={TermsAndConditions} />

          <GladventRoute exact path='/' component={Home} />
          <GladventRoute exact path='/listings' component={Listings} />
          <GladventPrivateRoute exact path='/account' component={Account} />
          <GladventPrivateRoute exact path='/calendar' component={Calendar} />
          <GladventPrivateRoute exact path='/calendar/:day' component={Calendar} />

          <GladventAdminRoute exact path='/admin' component={AdminDashboard} />
          <GladventAdminRoute exact path='/admin/outlets' component={AdminOutlets} />
          <GladventAdminRoute exact path='/admin/outlets/add' component={AddOutlet} />
          <GladventAdminRoute exact path='/admin/outlets/edit/:outletId' component={EditOutlet} />
          <GladventAdminRoute exact path='/admin/places' component={Places} />
          <GladventAdminRoute exact path='/admin/users' component={Users} />
          <GladventAdminRoute exact path='/admin/days' component={Days} />
          <GladventAdminRoute exact path='/admin/days/add' component={AddDay} />
          <GladventAdminRoute exact path='/admin/days/edit/:dayId' component={EditDay} />
          <GladventAdminRoute exact path='/admin/categories' component={Categories} />
          <GladventAdminRoute exact path='/admin/categories/add' component={AddCategory} />
          <GladventAdminRoute exact path='/admin/categories/edit/:categoryId' component={EditCategory} />

          <GladventAdminRoute exact path='/admin/email-templates' component={EmailTemplates} />
          <GladventAdminRoute exact path='/admin/email-templates/add' component={AddEmailTemplate} />
          <GladventAdminRoute exact path='/admin/email-templates/edit/:emailTemplateId' component={EditEmailTemplate} />


          {/* <PrivateRoute exact path='/dashboard' component={Dashboard} /> */}

          <Route component={NoMatch} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
