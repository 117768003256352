import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Breadcrumb from '../../App/layout/AdminLayout/Breadcrumb';

import { Button, Image, Form, Alert } from 'react-bootstrap';

import axios from '../../dealio-axios';
import { AxiosResponse } from 'axios';

import { useFormik, Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';

import logo from '../../assets/images/glc-logo.png';
import GladventPlainApp from '../GladventPlainApp';
import SaveButton from '../../Common/SaveButton';

const ForgottenPassword : React.FC = (props: any) => {
  const [processing, setProcessing] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [complete, setComplete] = useState(false);

  const forgottenPasswordSchema = yup.object().shape({
    emailAddress: yup.string().email("Please enter a valid email address")
      .required("Email addresss is required"),
  });
  
  useEffect(() => {
    document.title = 'Forgotten Password | Gladvent Calendar';

    // If you have a JWT then just redirect now
    if (window.localStorage.getItem('jwt'))
      setIsLoggedIn(true);
  }, []);

  const forgottenPassword = (values: any) => {
    setProcessing(true);

    axios.post('/api/user/forgotten-password', values)
    .then((response: AxiosResponse) => {
      console.log("response", response);
      setComplete(true);
    })
    .catch((response: AxiosResponse) => {
      setComplete(true);
    })
    .finally((response: AxiosResponse) => {
      setProcessing(false);
    });
  }

  return(
    <GladventPlainApp>
      {isLoggedIn && <Redirect to="/dashboard" />}
      <Breadcrumb/>

      <Formik
        validationSchema={forgottenPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("login form values", values);
          forgottenPassword(values);
          setSubmitting(false);
        }}
        enableReinitialize={true}
        initialValues={{emailAddress: '', password: ''}}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            values,
            touched,
            isValid,
            errors,
          }) => (
        <FormikForm onSubmit={handleSubmit}>
      <div className="main auth-wrapper" style={{flexDirection: 'column'}}>
        <div className="auth-content">
          <div className="card">
            <div className="card-body">
              <div className="mb-4 text-center">
                <Link to='/'><img src={logo} className="logo" alt="Gladvent Calendar"/></Link>
              </div>

              <h3 className="mb-4">Forgotten Password</h3>

              {complete && <Alert variant="success">If your email address exists, you will be sent an email link to change your password</Alert>}
              {!complete && <p>If you have forgotten your password, enter your email address below.</p>}

              <Form.Group controlId="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control name="emailAddress"
                  type="email" placeholder="Email address" value={values.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={touched.emailAddress && errors.emailAddress ? "error" : ""}
                />
                {touched.emailAddress && errors.emailAddress ? (
                  <div className="error-message">{errors.emailAddress}</div>
                ): null}
              </Form.Group>

              <SaveButton processing={processing}>Reset Password</SaveButton>
              <div style={{marginTop:20}}>
                <p className="mb-2 text-muted">Login <Link to="/login">Login</Link></p>
                <p className="mb-0 text-muted">Don’t have an account? <Link to="/register">Register</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </FormikForm>
          )}
          </Formik>
    </GladventPlainApp>
  );
}

export default ForgottenPassword;