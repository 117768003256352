import React, { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import EmailTemplateForm from './EmailTemplateForm';

import Aux from '../../hoc/_Aux';

const AddEmailTemplate: React.FC = (props: any) => {
  useEffect(() => {
    document.title = 'Add Email Template | Gladvent Calendar';
  }, []);

  return (
    <Aux>
      <h1>Add Email Template</h1>

      <Row>
        <Col>
          <Card>
            <Card.Body>
            <EmailTemplateForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}

export default AddEmailTemplate;