import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import AdminNav from '../AdminNav';

import DayForm from './DayForm';

interface IProps {
}

const AddDay: React.FC<IProps> = (props) => {
  const [day, setOutlets] = useState([]);

  useEffect(() => {
    document.title = "Add Day | Gladvent Calendar";

    // axios.get('/api/adminoutlets')
    // .then((response: AxiosResponse) => {
    //   console.log(response.data);
    //   setOutlets(response.data);
    // });
  }, []);

  return (
    <>
    <h1>Add Day</h1>

    <DayForm />

    <AdminNav />
</>
  )
}

export default AddDay;