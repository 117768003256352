import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { API_ROOT } from './api-config';

//import authService from './components/api-authorization/AuthorizeService';

const instance: any = axios.create({ 
  baseURL: API_ROOT, //process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    //headerType: 'example header type'
  },
});

//axios.defaults.responseType = 'json';
//axios.defaults.baseURL = process.env.REACT_APP_API_URL;

instance.interceptors.request.use((config: AxiosRequestConfig) => {
    // Do something before request is sent
    // If we have a token, then attach it
    const token = window.localStorage.getItem('jwt');

    if (token) config.headers.Authorization = `Bearer ${token}`;
    
    return config;
  },
  (error : AxiosError) => {
    console.log('AXIOS error', error);

    Promise.reject(error);
  }
);

instance.interceptors.response.use((response: AxiosResponse) => {
//  Promise.resolve(response);
  return response;
},
(error : AxiosError) => {
  if (error.response?.status === 401 && error.response?.headers['www-authenticate'].startsWith('Bearer error="invalid_token", error_description="The token expired')) {
    window.localStorage.removeItem('jwt');
    
    window.localStorage.removeItem('user:displayname');
    window.localStorage.removeItem('user:username');
    window.localStorage.removeItem('user:image');
    window.localStorage.removeItem('user:roles');

    // Redirect the user back to the main page
    window.location.href = "/";
  }

  Promise.reject(error);
}
);

export default instance;
