import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Aux from '../../../hoc/_Aux';
import Breadcrumb from "../../../App/layout/AdminLayout/Breadcrumb";

import { Button, Form, Image, Alert } from 'react-bootstrap';

import axios from  '../../../dealio-axios';
import Axios, { AxiosResponse } from 'axios';

import { useFormik, Formik, Form as FormikForm, Field, FormikConfig } from 'formik';
import * as yup from 'yup';
import GladventPlainApp from '../../GladventPlainApp';

import logo from '../../../assets/images/glc-logo.png';

const Login : React.FC = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const loginSchema = yup.object().shape({
    emailAddress: yup.string().email("Please enter a valid email address")
      .required("Email addresss is required"),
    password: yup.string()
      .required("Password is Required.")
      //.max(13,"Too long")
      //.min(8, "Too short")
  });
  
  useEffect(() => {
    document.title = 'Login | Gladvent Calendar';

    // If you have a JWT then just redirect now
    if (window.localStorage.getItem('jwt'))
      setIsLoggedIn(true);
  }, []);

  const login = (values: any) => {
    axios.post('/api/user/login', values)
    .then((response: AxiosResponse) => {
      console.log("response", response);     

      if (response.status === 200) {
        console.log("setting localstorage");
        window.localStorage.setItem('user:displayname', response.data.displayName);
        window.localStorage.setItem('user:username', response.data.username);
        window.localStorage.setItem('user:image', response.data.image);
        window.localStorage.setItem('user:roles', response.data.roles);
        window.localStorage.setItem('jwt', response.data.token);

        setIsLoggedIn(true);
      }
    })
    .catch((response: AxiosResponse) => {
      setLoginError(true);
    });
  }

  return(
    <GladventPlainApp>
    <Aux>
      {isLoggedIn && <Redirect to="/calendar" />}

      <main className="main h-100 w-100">
		<div className="container h-100">
			<div className="row h-100">
				<div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
					<div className="d-table-cell align-middle">

						<div className="card">
							<div className="card-body">
                <div className="text-center mt-4">
                  <Link to='/'><img src={logo} className="logo" alt="Gladvent Calendar"/></Link>

                  {/* <h1 className="h2">Login</h1> */}
                  <p className="lead">
                    Sign in to your account to continue
                  </p>
                </div>

								<div className="m-sm-4">

									{/* <div className="text-center">
										<img src="img/avatars/avatar.jpg" alt="Linda Miller" className="img-fluid rounded-circle" width="132" height="132" />
									</div> */}

                  <Formik
        validationSchema={loginSchema}
        onSubmit={(values, { setSubmitting }) => {
          setLoginError(false);

          console.log("login form values", values);
          login(values);
          setSubmitting(false);
        }}
        enableReinitialize={true}
        initialValues={{emailAddress: '', password: ''}}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            values,
            touched,
            isValid,
            errors,
          }) => (
        <FormikForm onSubmit={handleSubmit}>
          {loginError && <Alert variant="danger">Your login details are not correct</Alert>}

          <Form.Group controlId="emailAddress">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="emailAddress"
                  type="email" placeholder="Enter your email" value={values.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control-lg ${touched.emailAddress && errors.emailAddress ? "error" : ""}`}
                />
                {touched.emailAddress && errors.emailAddress ? (
                  <div className="error-message">{errors.emailAddress}</div>
                ): null}
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control name="password" type="password" placeholder="Enter your password" value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control-lg ${touched.emailAddress && errors.emailAddress ? "error" : ""}`}
                />
                {touched.password && errors.password ? (
                  <div className="error-message">{errors.password}</div>
                ): null}
								<small>
                  <Link to="/forgotten-password">Forgot Password?</Link>
                </small>
              </Form.Group>


										<div>
											<div className="form-check align-items-center">
												<input id="customControlInline" type="checkbox" className="form-check-input" value="remember-me" name="remember-me" checked />
												<label className="form-check-label text-small" htmlFor="customControlInline">Remember me next time</label>
											</div>
										</div>
										<div className="mt-3">
                      <Button type="submit" className="btn btn-lg btn-primary" disabled={isSubmitting}>{isSubmitting ? 'Loading…' : 'Sign in'}</Button>
										</div>
                    </FormikForm>
                                        )}
                    </Formik>


                    <p className="modal-alternative mb-0 text-muted">Don't have an account? <Link to='/register'>Register now</Link></p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</main>
    </Aux>
    </GladventPlainApp>
  );
}

export default Login;