import React from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router-dom';
import GladventApp from './Gladvent/GladventApp';

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  exact: any;
  path: any;
}

const GladventRoute: React.FC<IProps> = ({component: Component, ...rest}) => {

  return (
    <GladventApp>
      <Route
        {...rest}
        render={(props: any) => <Component {...props} /> }
      />
    </GladventApp>
  )
}

export default GladventRoute;