import React, { useState, useEffect } from 'react';
import { Alert, Form, Table } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';

import axios from '../../../dealio-axios';
import { AxiosResponse } from 'axios';

import {
  Form as FormikForm,
  Formik,
} from "formik";
import * as yup from "yup";
import FormField from '../../../Common/FormField';

interface IProps {
  outlet?: any
}

const OutletForm: React.FC<IProps> = (props) => {
  const [saved, setSaved] = useState(false);
  let history = useHistory();
  let method = props.outlet != null ? 'update' : 'add';

  const outletSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const onSubmit = async (values: any) => {
    // do something with it
    console.log(values);

    axios.post('/api/adminoutlets/' + method, values).then((response: AxiosResponse) => {
      console.log(response.data);

      // if it worked then what?
      setSaved(true);
      history.push('/admin/outlets?saved=true');
    });
  } 

  return (
    <>
      <Formik
        validationSchema={outletSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("form values", values);
          onSubmit(values);
          setSubmitting(false);
        }}
        enableReinitialize={true}
        initialValues={props.outlet || {name: '', category: '', subcategory: '', isLogo: false}}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={values?.id} />

            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.name && errors.name ? "error" : ""}
              />
              {touched.name && errors.name ? (
                <div className="error-message">{errors.name}</div>
              ) : null}
            </Form.Group>

            <FormField name="url" title="Url" values={values} touched={touched} errors={errors} onChange={handleChange} onBlur={handleBlur} />

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                placeholder="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.description && errors.description ? "error" : ""}
              />
              {touched.description && errors.description ? (
                <div className="error-message">{errors.description}</div>
              ) : null}
            </Form.Group>

            <FormField name="companyId" title="Company Id" values={values} touched={touched} errors={errors} onChange={handleChange} onBlur={handleBlur} />
            <FormField name="location" title="Location" values={values} touched={touched} errors={errors} onChange={handleChange} onBlur={handleBlur} />
            <FormField name="countryId" title="Country Id" values={values} touched={touched} errors={errors} onChange={handleChange} onBlur={handleBlur} />
            <FormField name="postcode" title="Postcode" values={values} touched={touched} errors={errors} onChange={handleChange} onBlur={handleBlur} />
            <FormField name="image" title="Image" values={values} touched={touched} errors={errors} onChange={handleChange} onBlur={handleBlur} />

            <Form.Group controlId="isLogo">
              <Form.Label>Is Logo?</Form.Label>
              <Form.Check name="isLogo" checked={values.isLogo} onChange={handleChange}>
              </Form.Check>
            </Form.Group>

            <FormField name="categoryId" title="CategoryId" values={values} touched={touched} errors={errors} onChange={handleChange} onBlur={handleBlur} />

            <button
              type="submit"
              className="btn btn-primary shadow-2 mb-4"
              disabled={isSubmitting}
            >
              Save
            </button>
          </FormikForm>
        )}
      </Formik>

      <p>
        <Link className="btn btn-outline-secondary" to="/admin/outlets">Back to Outlets</Link>
      </p>

</>
  )
}

export default OutletForm;