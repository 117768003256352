import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface IProps {
  outlets?: any
}

const BusinessCard: React.FC<IProps> = (props) => {
  return (
    <Carousel autoPlay={true} swipeable={true}>
    {props.outlets && props.outlets.map((outlet: any, index: number) => (
      <div key={index}>
        <p className="featured-site">Featured Site</p>
        <h2>{outlet.name}</h2>
        <p className="place-name">{outlet.location}</p>

        <img src={outlet.image} alt={outlet.name} />

        <p>{outlet.description}</p>

        <p>
          <a href={outlet.url} target="_blank" rel="noreferrer" className="btn btn-secondary">Visit Site</a>
        </p>
      </div>
      ))}
    </Carousel>
  )
}

export default BusinessCard;