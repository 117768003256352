import React from 'react';
import { Form } from 'react-bootstrap';

interface FormFieldProps {
  name: string,
  title: string,
  placeholder?: string,
  values: any,
  touched: any,
  errors: any,
  onChange: any,
  onBlur: any,
}

const FormField: React.FC<FormFieldProps> = ({name, title, placeholder, values, touched, errors, onChange, onBlur}) => {
  return <Form.Group controlId={name}>
  <Form.Label>{title}</Form.Label>
  <Form.Control
    type="text"
    name={name}
    placeholder={placeholder || title}
    value={values[name]}
    onChange={onChange}
    onBlur={onBlur}
    className={touched[name] && errors[name] ? "error" : ""}
  />
  {touched[name] && errors[name] ? (
    <div className="error-message">{errors[name]}</div>
  ) : null}
</Form.Group>
}

export default FormField;
