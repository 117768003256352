import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import logo from '../assets/images/glc-logo.png';
import calendar from '../assets/images/calendar.gif';
import BusinessCard from './BusinessCard';

import axios from '../dealio-axios';
import { AxiosResponse } from 'axios';

interface IProps {
}

const Home: React.FC<IProps> = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [outlets, setOutlets] = useState([]);

  useEffect(() => {
    document.title = "Gladvent Calendar";

    if (window.localStorage.getItem('jwt')) {
      setIsLoggedIn(true);
    };

    axios.get('/api/adminoutlets/get-random')
    .then((response: AxiosResponse) => {
      console.log(response.data);
      setOutlets(response.data);
    });
  }, []);

  return (
    <>
      {isLoggedIn && <Redirect to="/calendar" />}

      <div className="gl-title">
        <img src={logo} alt="Gladvent Calendar"/>
      </div>


      <div className="gl-calendar">
        <img src={calendar} alt="Gladvent Calendar"/>
        
        <div className="gl-body-block">
        <div className="gl-body">
          <div className="gl-form">
            <div className="home-hero">
            {/* <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
          <polygon points="50,0 100,0 50,100 0,100"></polygon>
        </svg> */}
              <div className="row">
                <div className="col-md-8">
                  <h1>Gladvent Calendar</h1>
                  
                  <h2 className="first">Daily suggestions to support</h2>
                  <h2 className="second">local businesses &amp; places</h2>

                  <p style={{fontSize:20}}>A fun and festive way to support local businesses/services/places in the run up to Christmas. Places you're glad
                  are still around and would be glad to see still going strong in 2021. Get a little suggestion each day for an idea of something you can do to help
                  businesses around you or raise awareness.</p>

                  <p>Create an account to see daily suggestions, or to send in recommendations. Share the site with your friends!</p>

                  <div className="home-buttons">
                    <Link className="btn btn-primary btn-lg" to='/login' style={{marginRight: 20}}>Login</Link>
                    <Link className="btn btn-primary btn-lg" to='/register'>Register</Link>
                  </div>

                  <div style={{position: 'absolute', bottom: 30}}>
                    <Link className="btn btn-secondary" to='/listings'>View Listings</Link>
                  </div>

                </div>
                <div className="col-md-4 home-business-card">
                  <BusinessCard outlets={outlets} />
                </div>
              </div>

            </div>
          </div>
        
        </div>
      </div>
      </div>
      
      <div className="gl-copy">
        <p>Copyright &copy; 2020 - <a style={{color: '#666'}} href="https://propellersoftware.net">propeller software</a></p>
      </div>

    </>
  )
}

export default Home;