import React from 'react';
import { Link } from 'react-router-dom';

import logo from './assets/images/glc-logo.png';

const NoMatch : React.FC = () => {
  return (
    <div className="container">
      <img src={logo} className="logo" alt="Gladvent Calendar" style={{maxWidth: 800}}/>

      <h1 style={{margin: '1em 0'}}>The page you are looking for could not be found</h1>

      <p>
        <Link className='btn btn-primary' to='/'>Go to Homepage</Link>
      </p>
    </div>
  );
}

export default NoMatch;
