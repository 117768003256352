import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import logo from '../assets/images/glc-logo.png';
import calendar from '../assets/images/calendar.gif';

interface IProps {
}

const Account: React.FC<IProps> = (props) => {
  useEffect(() => {
    document.title = "Account | Gladvent Calendar";
  }, []);

  return (
    <>
     <div className="gl-title">
        <img src={logo} alt="Gladvent Calendar"/>
      </div>

      <div style={{padding: 20}}>
        <h1>Your Account</h1>

        <p>Change password</p>
        <p>Edit Settings</p>
      </div>
      
      <div className="gl-copy">
        <p>Copyright &copy; 2020 - <a style={{color: '#666'}} href="https://propellersoftware.net">propeller software</a></p>
      </div>
</>
  )
}

export default Account;