import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

interface IProps {
}

const PrivacyPolicy: React.FC<IProps> = (props) => {
  useEffect(() => {
    document.title = "Privacy Policy | Gladvent Calendar";
  }, []);

  return (
    <>
    <h1>PrivacyPolicy</h1>
</>
  )
}

export default PrivacyPolicy;